<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import Tailwind from "../../Tailwind.svelte";
  import PDFPage from "../../components/PDFPage.svelte";
  import { closeModal, Modals, modals, openModal } from "svelte-modals";
  import SectionModal from "../../components/sections/SectionModal.svelte";
  import DrawingCanvas from "../../components/DrawingCanvas.svelte";
  import SortableList from "svelte-sortable-list";
  import { slide } from "svelte/transition";
  import Header from "../../components/Header.svelte";
  import prepareAssets, {
    fetchFont,
    getAsset,
  } from "../../utils/prepareAssets.js";
  import { v4 as uuidv4 } from "uuid";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "../../utils/asyncReader.js";
  import { ggID } from "../../utils/helper.js";
  import { save } from "../../utils/PDF.js";
  import axios from "axios";
  import ImageUrl from "./ImageUrl.svelte";
  import { navigate } from "svelte-routing";
  import { tapout } from "../../utils/tapout";
  import {
    create_history,
    delete_history,
    get_last_changes,
  } from "../../utils/history";
  import Swal from "sweetalert2";
  import SideBar from "../../components/SideBar.svelte";
  import BackToTop from "../../components/BackToTop.svelte";
  import BackToPreviousPage from "../../components/BackToPreviousPage.svelte";
  import GotoNextPage from "../../components/GotoNextPage.svelte";
  import { onDestroy } from "svelte";
  import Arrowback from "../../assets/svg/arrowback.svelte";
  import Logo from "../../assets/svg/logo.svelte";
  import Back from "../../assets/svg/back.svelte";
  import Arrowforward from "../../assets/svg/arrowforward.svelte";
  import ContextMenu from "../../components/ContextMenu/ContextMenu.svelte";
  import Neopolis from "../../assets/svg/neopolis.svelte";
  import User from "../../assets/svg/user.svelte";
  import Add from "../../assets/svg/add.svelte";
  import EditIcon from "../../assets/svg/EditIcon.svelte";
  import TickIcon from "../../assets/svg/TickIcon.svelte";
  import DragIcon from "../../assets/svg/dragIcon.svelte";
  import { _ } from "../../services/i18n";
  import LoadingPdf from "../../assets/svg/LoadingPdf.svelte";
  import LocalStorageService from "../../utils/axios/localStorageService";
  import { StartUpdateModelTutorial } from "../../utils/tutorial";
  import GeneratingLoader from "../../assets/svg/generatingLoader.svelte";

  onDestroy(() => {
    let child = document.getElementById("portal");
    if (child) {
      document.body.removeChild(child);
    }
  });
  let generating = false;
  const genID = ggID();
  let children = [];
  let pdfFile;
  let pdfName = "";
  let pages = [];
  let pagesScale = [];
  let allObjects = [];
  let allSections = [];
  let SelectedSection = 0;
  let currentFont = "Courier";
  let focusId = null;
  export let id;
  let selectedPageIndex = -1;
  let saving = false;
  let addingDrawing = false;
  let editMode = "MOVE";
  let sidebar = false;
  let scale = 1;
  let globalmousex;
  let globalmousey;
  let pdfpageHeight;
  let user = localStorage.getItem("user");
  let scroll;
  let position;
  let details;
  let shawdow = "#945FAF";
  let goToprevStep = 0;
  let list = [
    { id: 1, name: "First Item" },
    { id: 2, name: "Second Item" },
    { id: 3, name: "Third Item" },
  ];
  const sortList = (ev, idx) => {
    allSections[idx].ids = ev.detail;
  };
  // for test purpose
  onMount(async () => {
    let APIKEY = localStorage.getItem("api_key");
    if (!LocalStorageService.getToken()) {
      window.location = "/";
    } else {
      await axios
        .get(
          `${process.env.BASE_URL}/editique_pdf/get_pdf_fields_by_doc_id/${id}`,
          {
            headers: {
              apiKey: APIKEY,
            },
          }
        )
        .then((res) => {
          allObjects = JSON.parse(res.data.fieldsIds);
          pdfName = res.data.modelName;
          if (res.data.sections) allSections = JSON.parse(res.data.sections);
          onUploadPDF(res.data.fileUrl);
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            // title: "Oops...",
            text: $_("workspace.Something went wrong"),
          }).then(() => {
            setTimeout(() => navigate("/"), 1000);
          });
        });
      sessionStorage.clear();
    }
  });
  function updateIndex(index) {
    selectedPageIndex = index;
  }
  async function onUploadPDF(url) {
    axios
      .get(`${process.env.BASE_URL}/${url.substring(1)}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        await addPDF(res.data);
        selectedPageIndex = 0;
      })
      .catch((err) =>
        Swal.fire({
          icon: "error",
          text: $_("workspace.Fichier_introuvable"),
        }).then(() => {
          setTimeout(() => navigate("/"), 500);
        })
      );
  }
  async function exportPDF() {
    const download = await getAsset("download");
    const PDFLib = await getAsset("PDFLib");

    let pdfDoc = await PDFLib.PDFDocument.load(
      await readAsArrayBuffer(pdfFile)
    );
    pdfDoc.setKeywords([JSON.stringify(allObjects)]);
    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, pdfName, "application/pdf");
  }

  async function generateUrl() {
    generating = true;
    axios
      .post(
        `${process.env.BASE_URL}/liveprint/create_url`,
        {
          idModel: id,
        },
        {
          headers: {
            apiKey: localStorage.getItem("api_key"),
          },
        }
      )
      .then((res) => {
        generating = false
        if (res.data.url) {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        generating = false
        Swal.fire({
          icon: "error",
          // title: "Oops...",
          text: err.response.data.message,
        });
      });
  }

  async function addPDF(file) {
    try {
      const pdf = await readAsPDF(file);
      pdfFile = file;
      const numPages = pdf.numPages;
      pages = Array(numPages)
        .fill()
        .map((_, i) => pdf.getPage(i + 1));
      /* allObjects = pages.map(() => []); */
      pagesScale = Array(numPages).fill(1);
      StartUpdateModelTutorial();
    } catch (e) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Verifier l'intégrité  de votre fichier"),
      });
      throw e;
    }
  }
  async function onUploadImage() {
    if (selectedPageIndex >= 0) {
      addImage();
    }
  }
  function updateMode(mode) {
    editMode = mode;
  }

  async function addImage() {
    try {
      fetchFont(currentFont);
      let id = Date.now();
      const object = {
        id: id,
        type: "image",
        width: 150,
        height: 50,
        x: 0,
        y: 0,
        size: 14,
        lineHeight: 1,
        fontFamily: currentFont,
        fieldType: "Text",
        TextFormat: "Basique",
        FormatCode: 0,
        charactersNumber: 0,
        lines: $_("workspace.champText"),
        visibility: "Oui",
        relation: "",
        color: { r: 0, g: 0, b: 0 },
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );

      if (allSections.length != 0) {
        InsertIntoSection(object);
        /* children = [...children, { id: id }]; */
      }
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  function onCopy(obj, pos) {
    const {
      width,
      height,
      size,
      lineHeight,
      line,
      charactersNumber,
      fieldType,

      visibility,
    } = obj;
    try {
      fetchFont(currentFont);
      const object = {
        id: Date.now(),
        type: "image",
        width: width,
        height: height,
        x: pos.detail.x / 3,
        y: pos.detail.y / 3,
        size: size,
        lineHeight: lineHeight,
        fontFamily: currentFont,
        fieldType: fieldType,
        charactersNumber: charactersNumber,
        lines: line,
        visibility: visibility,
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(e);
    }
  }

  async function copyImage(obj) {
    const {
      id,
      width,
      height,
      size,
      lineHeight,
      line,
      charactersNumber,
      fieldType,
      x,
      y,
      visibility,
    } = obj.detail;
    try {
      fetchFont(currentFont);
      const object = {
        id: id,
        type: "image",
        width: width,
        height: height,
        x: x,
        y: y,
        size: size,
        lineHeight: lineHeight,
        fontFamily: currentFont,
        fieldType: fieldType,
        charactersNumber: charactersNumber,
        lines: line,
        visibility: visibility,
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  function onAddTextField() {
    if (selectedPageIndex >= 0) {
      addTextField();
    }
  }
  function addTextField(text = "New Text Field") {
    const id = genID();
    fetchFont(currentFont);
    const object = {
      id,
      text,
      type: "text",
      size: 16,
      width: 0, // recalculate after editing
      lineHeight: 1,
      fontFamily: currentFont,
      x: 0,
      y: 0,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function handleOpenImport() {
    openModal(SectionModal, {
      addSection: (Nom, Niveau) => {
        addSection(Nom, Niveau);
      },
      onOpenAnother: () => {
        handleOpenImport();
      },
    });
  }
  function insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
    return array;
  }
  function addSection(Nom, Niveau) {
    allSections = insertAt(allSections, Niveau - 1, { name: Nom, ids: [] });
  }

  function InsertIntoSection(obj) {
    let insertionSection = allSections[SelectedSection];
    insertionSection.ids = [...insertionSection.ids, obj];
    allSections[SelectedSection] = insertionSection;
  }
  function addDrawing(originWidth, originHeight, path, scale = 1) {
    const id = genID();
    const object = {
      id,
      path,
      type: "drawing",
      x: window.pageYOffset,
      y: window.pageXOffset,
      originWidth,
      originHeight,
      width: originWidth * scale,
      scale,
    };

    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function selectFontFamily(event) {
    const name = event.detail.name;
    fetchFont(name);
    currentFont = name;
  }
  function selectPage(index) {
    selectedPageIndex = index;
  }

  function updateSection(objectId, object) {
    allSections[SelectedSection] &&
      allSections[SelectedSection].ids.find((element, idx) => {
        if (element.id === objectId) {
          allSections[SelectedSection].ids[idx] = object;
        }
      });
  }
  function updateObject(objectId, payload) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.map((object) => {
            if (object.id === objectId) {
              if (payload.lines) {
                updateSection(objectId, { ...object, ...payload });
              }
              return { ...object, ...payload };
            } else {
              return object;
            }
          })
        : objects
    );

    create_history(allObjects);
  }

  function transferObject(objectId) {
    let find = allObjects[objectId.currentpage].filter(
      (e) => e.id == objectId.id
    );
    find[0].y = 0;
    find[0].height = objectId.height;
    find[0].width = objectId.width;
    allObjects[objectId.currentpage] = allObjects[objectId.currentpage].filter(
      (e) => e.id != objectId.id
    );
    allObjects[objectId.page] = [...allObjects[objectId.page], ...find];
    selectedPageIndex = objectId.page;
    window.scrollTo({
      top: pdfpageHeight * 2.2 * selectedPageIndex,
      behavior: "smooth",
    });
  }

  function updateToPreviousObjects(prevObjects) {
    allObjects = prevObjects;
  }

  function deleteFromSection(objectId) {
    let newSections = allSections;
    newSections.map((section) => {
      section.ids.map((element, index) => {
        if (element.id == objectId) {
          section.ids.splice(index, 1);
        }
      });
    });
    let newChildren = children;
    newChildren.map((child, index) => {
      if (child["$capture_state"]().id == objectId) {
        newChildren.splice(index, 1);
      }
    });
    allSections = newSections;
    children = newChildren;
  }
  const data = {
    foo: [1, 2, 3],
    bar: [4, 5, 6, 7, 8, 9, 10, 11],
    baz: [12, 24, 42],
  };
  function deleteObject(objectId) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.filter((object) => object.id !== objectId)
        : objects
    );
    deleteFromSection(objectId);
  }

  function deleteCopyImage(id) {
    deleteObject(id);
  }
  function onMeasure(scale, i) {
    pagesScale[i] = scale;
  }
  function onChangePdfName() {
  }
  const disconnect = () => {
    localStorage.clear();
    window.location.pathname = "/";
  };
  async function updatePDF() {
    if (!pdfFile || saving || !pages.length) return;
    saving = true;

    /* await save(pdfFile, allObjects, pdfName, pagesScale); */
    const data = allObjects;

    axios
      .put(
        `${process.env.BASE_URL}editique_pdf/update_pdf_fields_by_doc_id/${id}`,
        {
          data: data,
          name: pdfName,
          sections: allSections,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((res) => {
        saving = false;
        if (res.data) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: $_("workspace.travailEnregistré"),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) =>
        Swal.fire({
          icon: "error",
          text: $_("workspace.Erreur de modification"),
        }).then(() => {
          setTimeout(() => navigate("/"), 1000);
        })
      );
  }
  /* function openSidebar() {
    sidebar != sidebar;
    document.querySelector(".sidebar").classList.toggle("hidden");
  } */
</script>

<svelte:window
  on:dragenter|preventDefault
  on:dragover|preventDefault
  on:drop|preventDefault={onUploadPDF}
  bind:scrollY={scroll}
/>
<Tailwind />

<nav class=" sticky top-0 z-10" style="background-color: #151416;">
  <Header />
  <!-- <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
    <div class="flex h-16 items-center justify-between">
      <div class="flex items-center">
        <div class="flex-shrink-0">
          <Neopolis />
        </div>
        <div class="hidden md:block">
          <div class="ml-10 flex items-baseline space-x-4" />
        </div>
      </div>
      <div class="text-white flex ">
        <User />
        {user}
      </div>
      <div class="hidden md:block">
        <div class="ml-4 flex items-center md:ml-6">
       
          <div class="relative ml-3">
            <div>
              <button
                type="button"
                class="flex max-w-xs items-center rounded-full  text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 text-white"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                on:click={disconnect}
              >


                Déconnecter
                <svg
                  class="ml-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="fill: white;"
                  ><path
                    d="M10 9.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7zm6-3c-1.787 0-3.46.474-4.911 1.295l.228.2 1.396 1.221c1.004-.456 2.114-.716 3.287-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.283-.26-3.288-.715l-1.396 1.221-.228.2c1.452.82 3.125 1.294 4.912 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z"
                  /></svg
                >
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="-mr-2 flex md:hidden">

        <button
          type="button"
          class="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
     
          <svg
            class="block h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          
          <svg
            class="hidden h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>

 
  <div class="md:hidden" id="mobile-menu">
    <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">
    
      <a
        href="/Workspaces"
        class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
        aria-current="page">Dashboard</a
      >

     
    </div>
  </div> -->
  <div
    class="  z-10 sticky left-0 right-0 top-16 h-87 flex items-center
     border-b navColor"
    style="display: flex; justify-content:space-between "
  >
    <button
      on:click={() => navigate("/HomeWorkspace")}
      type="button"
      class=" retourBtn py-1 px-3 mr-2 text-sm font-medium text-gray-900 focus:outline-none rounded-lg focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800"
    >
      <Back />
    </button>

    <button
      on:click={() => navigate("/HomeWorkspace")}
      type="button"
      class=" logoBtn py-1 px-3 mr-4 text-sm font-medium focus:outline-none focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    >
      <Logo />
    </button>
    <input
      type="file"
      name="pdf"
      id="pdf"
      on:change={onUploadPDF}
      class="hidden"
    />
    <input id="image" name="image" class="hidden" on:click={onUploadImage} />
    <div
      on:click={() => {
        let lastModification = delete_history(goToprevStep);
        lastModification && updateToPreviousObjects(lastModification);
        goToprevStep = 1;
      }}
      class="cursor-pointer m-2"
    >
      <Arrowback />
    </div>
    {#if goToprevStep == 1}
      <div
        class="cursor-pointer m-2"
        on:click={() => {
          goToprevStep = 0;
          updateToPreviousObjects(get_last_changes());
        }}
      >
        <Arrowforward />
      </div>
    {/if}
    <div
      class="outilsBars"
      style="  display: flex; flex-direction:row; background-color:#552E6A ; justify-content:space-between; border-radius:0.3rem;padding-inline:0.8rem;width:60%"
    >
      <p class=" outils text-white mr-4 mt-1 pt-1">{$_("workspace.Outils")}</p>

      <label
        class="addFieldBtn flex items-center justify-center h-full w-8 mr-2
    cursor-pointer"
        for="image"
        data-tooltip="ADD FIELD"
      >
        <svg
          class="mt-1 newChamp"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6 17H23.4M17 23.4V10.6M12.2 33H21.8C29.8 33 33 29.8 33 21.8V12.2C33 4.2 29.8 1 21.8 1H12.2C4.2 1 1 4.2 1 12.2V21.8C1 29.8 4.2 33 12.2 33Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </label>

      <div
        style="background-color:{editMode === 'MOVE'
          ? '#6E3F88'
          : '#552E6A'} ; width:3rem;margin-right:5px;cursor:pointer;height:{editMode ===
        'MOVE'
          ? '2.7rem'
          : ''}"
        data-tooltip="MOVE MODE"
        class="mr-4"
        on:click={() => (editMode = "MOVE")}
      >
        <svg
          class="move"
          style="margin-top:0.2rem;display:block;margin-right:auto;margin-left:auto;"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.6 7.4L7.4 26.6M12.2 33H21.8C29.8 33 33 29.8 33 21.8V12.2C33 4.2 29.8 1 21.8 1H12.2C4.2 1 1 4.2 1 12.2V21.8C1 29.8 4.2 33 12.2 33Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.6 13.7999V7.3999H20.2M7.40002 20.1999V26.5999H13.8M7.40002 7.3999L26.6 26.5999M7.40002 13.7999V7.3999H13.8M26.6 20.1999V26.5999H20.2"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        data-tooltip="EDIT MODE"
        style="background-color:{editMode === 'EDIT'
          ? '#6E3F88'
          : '#552E6A'} ; width:3rem;height:{editMode === 'EDIT'
          ? '2.7rem'
          : ''}"
      >
        <div on:click={() => (editMode = "EDIT")} style="cursor:pointer">
          <svg
            class="edit"
            style="margin-top:0.2rem;display:block;margin-right:auto;margin-left:auto;"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.2214 1.39746H12.0611C4.1603 1.39746 1 4.55776 1 12.4585V21.9394C1 29.8402 4.1603 33.0005 12.0611 33.0005H21.542C29.4427 33.0005 32.603 29.8402 32.603 21.9394V18.7791"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.1853 3.00873L10.7337 15.4603C10.2597 15.9344 9.78563 16.8667 9.69082 17.5461L9.01135 22.3024C8.75853 24.0247 9.97524 25.2257 11.6976 24.9886L16.4539 24.3092C17.1175 24.2144 18.0498 23.7403 18.5397 23.2663L30.9913 10.8147C33.1403 8.66568 34.1516 6.16904 30.9913 3.00873C27.831 -0.151568 25.3343 0.859729 23.1853 3.00873V3.00873Z"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div
        on:click={() => editMode === "MOVE" && onCopy(details, position)}
        style="cursor:{editMode === 'MOVE' ? 'pointer' : ''}"
        class=" copyy mr-2 mt-1"
      >
        {#if editMode == "MOVE"}
          <svg
            width="30"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6 23.4H6.488C2.824 23.4 1 21.576 1 17.912V6.488C1 2.824 2.824 1 6.488 1H13.8C17.464 1 19.288 2.824 19.288 6.488"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.592 21.7996H26.808M24.2 24.4076V19.1916M27.512 32.9996H20.2C16.536 32.9996 14.712 31.1756 14.712 27.5116V16.0876C14.712 12.4236 16.536 10.5996 20.2 10.5996H27.512C31.176 10.5996 33 12.4236 33 16.0876V27.5116C33 31.1756 31.176 32.9996 27.512 32.9996Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        {:else}
          <svg
            width="30"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6 23.4H6.488C2.824 23.4 1 21.576 1 17.912V6.488C1 2.824 2.824 1 6.488 1H13.8C17.464 1 19.288 2.824 19.288 6.488"
              stroke="#70557E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.592 21.7996H26.808M24.2 24.4076V19.1916M27.512 32.9996H20.2C16.536 32.9996 14.712 31.1756 14.712 27.5116V16.0876C14.712 12.4236 16.536 10.5996 20.2 10.5996H27.512C31.176 10.5996 33 12.4236 33 16.0876V27.5116C33 31.1756 31.176 32.9996 27.512 32.9996Z"
              stroke="#70557E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        {/if}
      </div>
    </div>
    <input
      placeholder={$_("workspace.nomModele")}
      type="text"
      class="nameInput"
      style="border-radius: 0.2rem;padding-left:0.5rem; margin:0.7rem;padding:0.3rem;width:48%; background-color:white"
      bind:value={pdfName}
      on:change={onChangePdfName}
    />

    <button
      on:click={updatePDF}
      class=" saveBtn text-white font-medium py-1 px-3
       mr-3 rounded"
      style="background-color: #71ACFA;width:15%"
      class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
      class:bg-blue-700={pages.length === 0 || saving || !pdfFile}
    >
      {saving ? "..." : $_("workspace.enregistrer")}
    </button>

    <button
      on:click={exportPDF}
      class=" exportBtn text-white font-medium py-1 px-3
       mr-3 rounded"
      style="background-color: #945FAF; width:15%"
      class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
      class:bg-blue-700={pages.length === 0 || saving || !pdfFile}
    >
      {$_("workspace.exporter")}
    </button>
    <div
      disabled
      class="importBtn font-medium mr-3 rounded cursor-pointer"
      style="background-color: #F3EAF8 !important; color:#945FAF !important; padding:0.2rem;width:30%;text-align:center"
      on:click={generateUrl}
    >
      {#if generating}
        <GeneratingLoader />
      {:else}
        {$_("workspace.generateLivePrint")}
      {/if}
    </div>

    <button
      on:click={(e) => {
        window.scrollTo({
          top: window.scrollY + pdfpageHeight * 2.3,
          behavior: "smooth",
        });

        selectedPageIndex < allObjects.length - 1 &&
          (selectedPageIndex = selectedPageIndex + 1);
      }}
      class="mr-2"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.3 19H12.7C17.2 19 19 17.2 19 12.7V7.3C19 2.8 17.2 1 12.7 1L7.3 1C2.8 1 1 2.8 1 7.3L1 12.7C1 17.2 2.8 19 7.3 19Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.82275 8.77637L9.99975 11.9444L13.1768 8.77637"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <!-- <h1
      on:click={() =>
        window.scrollTo({
          top: pdfpageHeight * 2.6 * selectedPageIndex,
          behavior: "smooth",
        })}
      class="text-white m-5 cursor-pointer"
    >
      Page sélectionnée
    </h1>-->
    <span
      class="rounded-l-sm npage"
      style="color: #898989; background-color:white; padding-inline:0.3rem; width:25% !important"
      >{$_("workspace.numPage")}</span
    >
    <form
      on:submit={(e) => {
        e.preventDefault();
        window.scrollTo({
          top: pdfpageHeight * 2.2 * selectedPageIndex,
          behavior: "smooth",
        });
      }}
    >
      <input
        type="number"
        min="1"
        max={allObjects.length}
        step="1"
        value={selectedPageIndex + 1}
        on:change|preventDefault={(e) =>
          (selectedPageIndex = e.target.value - 1)}
        class=" w-12 text-center flex-shrink-0 focus:outline-none rounded-r-sm"
        style="height:1.45rem"
      />
    </form>

    <button
      on:click={(e) => {
        window.scrollTo({
          top: window.scrollY - pdfpageHeight * 2.6,
          behavior: "smooth",
        });
        selectedPageIndex > 0 && (selectedPageIndex = selectedPageIndex - 1);
      }}
      class="ml-2"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.3 1H12.7C17.2 1 19 2.8 19 7.3V12.7C19 17.2 17.2 19 12.7 19H7.3C2.8 19 1 17.2 1 12.7V7.3C1 2.8 2.8 1 7.3 1Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.82275 11.2236L9.99975 8.05563L13.1768 11.2236"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>

    <h1
      style="width: 12%;"
      class="text-white m-5 cursor-pointer font-Roboto text-xs font-normal pagesN"
    >
      {allObjects.length} pages
    </h1>
  </div>
</nav>

{#if addingDrawing}
  <div
    transition:fly={{ y: -200, duration: 500 }}
    class="fixed z-10 top-0 left-0 right-0 border-b border-gray-300 bg-white
      shadow-lg"
    style="height: 50%;"
  >
    <DrawingCanvas
      on:finish={(e) => {
        const { originWidth, originHeight, path } = e.detail;
        let scale = 1;
        if (originWidth > 500) {
          scale = 500 / originWidth;
        }
        addDrawing(originWidth, originHeight, path, scale);
        addingDrawing = false;
      }}
      on:cancel={() => (addingDrawing = false)}
    />
  </div>
{/if}
{#if pages.length}
  <div class="flex justify-center px-5 w-full md:hidden">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-writing"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M20 17v-12c0 -1.121 -.879 -2 -2 -2s-2 .879 -2 2v12l2 2l2 -2z" />
      <path d="M16 7h4" />
      <path d="M18 19h-13a2 2 0 1 1 0 -4h4a2 2 0 1 0 0 -4h-3" />
    </svg>

    <input
      placeholder={$_("workspace.nomModele")}
      type="text"
      class="flex-grow bg-transparent"
      bind:value={pdfName}
      on:change={onChangePdfName}
    />
  </div>
  <div
    class="fixed top-0 z-1 bottom-0 lg:left-0 p-2 overflow-y-auto text-center sidebarr"
    style="width:20%"
  >
    <div class="sidebarContainer">
      <!--<div
        style="cursor:pointer"
        class="flex flex-row items-center justify-center text-white"
        on:click={handleOpenImport}
      >
        <div
          style="height: 50px;width:50px"
          class="flex items-center justify-center"
        >
          <Add />
        </div>
        <h1>{$_("workspace.Créer des sections")}</h1>
      </div>-->

      {#each allSections as section, index}
        <div
          class="w-full flex flex-col justify-between dark:bg-gray-800 bg-white bg-opacity-25 dark:border-gray-700 rounded-lg border border-gray-400 mb-6 py-5 px-4 {index ==
          SelectedSection
            ? 'borderHighlight'
            : ''}"
        >
          <div class="flex flex-row items-center justify-around">
            <h3
              class="text-white dark:text-white leading-7 font-semibold w-11/12"
            >
              {$_("workspace.nom")}: {section.name}
            </h3>

            <button
              on:click={() => {
                SelectedSection === index
                  ? (SelectedSection = -1)
                  : (SelectedSection = index);
              }}
              class="w-8 h-8 rounded-full dark:bg-gray-100 dark:text-white bg-gray-800 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
              aria-label="edit note"
              role="button"
              aria-expanded={index == SelectedSection}
            >
              <svg
                viewBox="0 0 1024 1024"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                ><g id="SVGRepo_bgCarrier" stroke-width="0" /><g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                /><g id="SVGRepo_iconCarrier"
                  ><path
                    fill="#ffffff"
                    d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"
                  /></g
                ></svg
              >
            </button>
          </div>

          <!-- {#each section.ids as id}
            <div
              class="font-normal text-white dark:text-gray-400 mt-2 linkContainer"
              on:click={() => {
                children.forEach((child, index) => {
                  if (child["$capture_state"]().id == id.id) {
                    selectedPageIndex = child["$capture_state"]().pIndex;
                    window.scrollTo({
                      top: pdfpageHeight * 2.5 * selectedPageIndex + 1,
                      behavior: "smooth",
                    });
                    editMode = "EDIT";
                    children[index].onFocusTool();
                    children[index].onFocus();
                  }
                });
              }}
            >
            {id.lines}
            <EditIcon />
            </div>
          {/each} -->
          <!-- <div>
            <div class="flex items-center justify-between text-gray-800">
              {#if index == SelectedSection}
                <TickIcon />
              {/if}
              <div />
              <p class="dark:text-gray-100 text-sm">March 28, 2020</p>
            </div>
          </div> -->
        </div>
        <!-- <div
          on:click={() => {
            SelectedSection = index;
          }}
          class="sectionItem block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 {index ==
          SelectedSection
            ? 'hightlightBorder'
            : ''}"
        >
          <div class="flex flex-row  justify-center items-center">
            <h5
              class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
            >
              {section.name}
            </h5>
          </div>
          {#each section.ids as id}
            <div
              class="font-normal text-gray-700 dark:text-gray-400 mt-2 linkContainer"
              on:click={() => {
                children.forEach((child, index) => {
                  if (child["$capture_state"]().id == id.id) {
                    selectedPageIndex = child["$capture_state"]().pIndex;
                    window.scrollTo({
                      top: pdfpageHeight * 2.5 * selectedPageIndex + 1,
                      behavior: "smooth",
                    });
                    editMode = "EDIT";
                    children[index].onFocusTool();
                    children[index].onFocus();
                  }
                });
              }}
            >
              <EditIcon />
              {id.lines}
            </div>
          {/each}
        </div> -->
        {#if index == SelectedSection}
          <SortableList
            list={section.ids}
            key="id"
            on:sort={(e) => sortList(e, index)}
            let:item
            let:index
          >
            <div
              class="font-normal text-white dark:text-white mt-2 linkContainer"
              transition:slide={{ duration: 300 }}
            >
              {item.lines}
              <div
                class="flex flex-row items-center justify-around"
                on:click={() => {
                  children.forEach((child, index) => {
                    if (child) {
                      if (child["$capture_state"]().id == item.id) {
                        selectedPageIndex = child["$capture_state"]().pIndex;
                        window.scrollTo({
                          top: pdfpageHeight * 2.5 * selectedPageIndex + 1,
                          behavior: "smooth",
                        });
                        editMode = "EDIT";
                        children[index].onFocusTool();
                        children[index].onFocus();
                      }
                    }
                  });
                }}
              >
                <EditIcon />
                <DragIcon />
              </div>
            </div>
          </SortableList>
        {/if}
      {/each}
    </div>
  </div>
  <div class="w-full">
    {#each pages as page, pIndex (page)}
      <div
        class="p-5 w-full flex flex-col items-end overflow-hidden"
        on:mousedown={() => selectPage(pIndex)}
        on:touchstart={() => selectPage(pIndex)}
      >
        <div
          class="relative shadow-lg {editMode === 'MOVE'
            ? 'cursor-move'
            : 'cursor-crosshair'}"
          class:shadow-outline={pIndex === selectedPageIndex}
        >
          <PDFPage
            on:measure={(e) => onMeasure(e.detail.scale, pIndex)}
            {scale}
            {page}
          />
          <div
            class="absolute top-0 left-0 transform origin-top-left"
            style="transform: scale({pagesScale[pIndex]}); touch-action: none;"
          >
            {#each allObjects[pIndex] as object, index (object.id)}
              {#if object.type === "image"}
                <ImageUrl
                  on:update={(e) => updateObject(object.id, e.detail)}
                  on:delete={() => deleteObject(object.id)}
                  bind:this={children[children.length]}
                  x={object.x}
                  y={object.y}
                  id={object.id}
                  width={object.width}
                  size={object.size}
                  lineHeight={object.lineHeight}
                  height={object.height}
                  fontFamily={object.fontFamily}
                  pageScale={pagesScale[pIndex]}
                  typeSelected={object.fieldType}
                  charactersNumber={object.charactersNumber}
                  line={object.lines}
                  relation={object.relation}
                  visibility={object.visibility}
                  livePrint={object.livePrint ? object.livePrint : "Non"}
                  color={object.color}
                  checkFormat={object.checkFormat}
                  dateFormat={object.dateFormat}
                  TextFormat={object.TextFormat}
                  langue={object.langue}
                  alignment={object.alignment}
                  _currentVariant={object.variant}
                  options={object.options ? object.options : []}
                  {editMode}
                  bind:details
                  bind:position
                  {allObjects}
                  {pIndex}
                  {pdfpageHeight}
                  {scroll}
                  bind:selectedPageIndex
                  on:mode={(e) => updateMode(e.detail)}
                  on:copy={(obj) => copyImage(obj)}
                  on:deletecopy={(obj) => deleteCopyImage(obj.detail)}
                  on:transfer={(obj) => transferObject(obj.detail)}
                />
              {/if}
            {/each}
          </div>
        </div>
      </div>
    {/each}
  </div>
{:else}
  <div
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center"
  >
    <LoadingPdf />
  </div>
{/if}
<!-- </main> -->

<BackToTop {selectedPageIndex} on:update={(e) => updateIndex(e.detail)} />

<BackToPreviousPage />
<GotoNextPage />

<Modals>
  <div slot="backdrop" class="backdrop" on:click={closeModal} />
</Modals>

<style>
  /*This would all go into the global.css file*/
  [data-tooltip] {
    position: relative;
    z-index: 2;
    display: block;
  }

  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-out;
    transform: translate(-50%, 5px);
  }

  [data-tooltip]:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    padding: 7px;
    width: 100%;
    min-width: 70px;
    max-width: 250px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    transition: 0.2s ease-out;
  }

  [data-tooltip]:after {
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid hsla(0, 0%, 20%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }
  [data-tooltip="false"]:hover:before,
  [data-tooltip="false"]:hover:after {
    visibility: hidden;
    opacity: 0;
  }
  .navColor {
    background-color: #3b194d;
  }
  .sidebarr {
    background-color: #624771;
  }
  .linkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2rem;
    cursor: pointer;
    width: 100%;
  }
  .sidebarContainer {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1590px) {
    .retourBtn {
      margin-left: 0;
      padding-right: 0;
      padding-left: 4px;
    }
    .retour {
      width: 28px;
    }
    .logoBtn {
      margin-right: 0.5rem;
      padding: 0;
    }
    .logo {
      width: 160px;
    }
    .outils {
      margin-right: 5px;
    }
    .outilsBars {
      display: flex;
      flex-direction: row;
      margin: 0.2rem;

      width: 25%;
    }
    .newChamp {
      width: 25px;
    }
    .move {
      width: 25px;
    }
    .edit {
      width: 25px;
    }
    .copyy {
      width: 25px;
      margin-right: 0.2rem;
    }
    .nameInput {
      width: 48% !important;
      border-radius: 0.2rem !important;
      padding: 0.3rem !important;
      margin: 0.2rem !important;
    }
    .saveBtn {
      padding-inline: 0.2rem;
      padding-block: 4px;
      font-size: 0.9rem;
      margin-right: 0.2rem;
      font-weight: 500;
      width: 15%;
    }
    .exportBtn {
      padding-inline: 0.4rem;
      padding-block: 4px;
      font-size: 0.9rem;
      margin-right: 0.2rem;
      font-weight: 500;
      width: 15%;
    }
    .importBtn {
      font-size: 0.9rem;
      font-weight: 500;
      width: 30%;
      text-align: center;
      margin-right: 0.3rem;
    }
    .pagesN {
      padding-left: 0.3rem;
      margin: 0;
      width: 12%;
    }

    .npage {
      width: 25%;
    }
  }
  .borderHighlight {
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
  }
  .sectionItem {
    width: 100%;
    margin-top: 2rem;
  }
  svg {
    transition: transform 0.2s ease-in;
  }

  [aria-expanded="true"] svg {
    transform: rotate(-0.25turn);
  }
</style>
