<script>
  import { onDestroy, onMount } from "svelte";
  import { fly } from "svelte/transition";
  import Tailwind from "../../Tailwind.svelte";
  import PDFPage from "../../components/PDFPage.svelte";
  import Image from "./Image.svelte";
  import DrawingCanvas from "../../components/DrawingCanvas.svelte";
  import prepareAssets, { fetchFont } from "../../utils/prepareAssets.js";
  import { v4 as uuidv4 } from "uuid";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "../../utils/asyncReader.js";
  import { ggID } from "../../utils/helper.js";
  import axios from "axios";
  import SimpleCrypto from "simple-crypto-js";
  import { navigate } from "svelte-routing";
  import { create_history, delete_history } from "../../utils/history";
  import Swal from "sweetalert2";
  import BackToTop from "../../components/BackToTop.svelte";
  import BackToPreviousPage from "../../components/BackToPreviousPage.svelte";
  import GotoNextPage from "../../components/GotoNextPage.svelte";
  import ImageUrl from "../AppUrl/ImageUrl.svelte";
  import Logo from "../../assets/svg/logo.svelte";
  import Neopolis from "../../assets/svg/neopolis.svelte";
  import Header from "../../components/Header.svelte";
  import { _ } from "../../services/i18n";
  import LoadingPdf from "../../assets/svg/LoadingPdf.svelte";
  import { StartModelingTutorial } from "../../utils/tutorial";
  const genID = ggID();
  let pdfFile;
  let pdfName = "";
  let pages = [];
  let pagesScale = [];
  let allObjects = [];
  let currentFont = "Times-Roman";
  let focusId = null;
  let selectedPageIndex = -1;
  let saving = false;
  let addingDrawing = false;
  let currentWorkspace;
  let pdfpageHeight;
  let editMode = "MOVE";
  let user = localStorage.getItem("user");
  let company_id = localStorage.getItem("company_id");
  // for test purpose
  onMount(async () => {
    let simpleCrypto = new SimpleCrypto(process.env.SVELTE_APP_SECRET_KEY);
    let workspacecrypted = localStorage.getItem("-");
    currentWorkspace = simpleCrypto.decrypt(workspacecrypted);
    try {
      const res = await fetch("/blank.pdf");
      const pdfBlob = await res.blob();
      await addPDF(pdfBlob);
      selectedPageIndex = 0;
      setTimeout(() => {
        fetchFont(currentFont);
        prepareAssets();
      }, 5000);

      // const imgBlob = await (await fetch("/test.jpg")).blob();
      // addImage(imgBlob);
      // addTextField("測試!");
      // addDrawing(200, 100, "M30,30 L100,50 L50,70", 0.5);
    } catch (e) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Verifier l'intégrité  de votre fichier"),
      });
    }
    sessionStorage.clear();
    window.addEventListener("keydown", onKeyDownTool, false);
  });
  onDestroy(() => {
    let child = document.getElementById("portal");
    if (child) {
      document.body.removeChild(child);
    }
  });
  const disconnect = () => {
    localStorage.clear();
    window.location.pathname = "/";
  };
  function onKeyDownTool(ev) {
    // function to check the detection
    ev = ev || window.event; // Event object 'ev'
    var key = ev.which || ev.keyCode; // Detecting keyCode

    // Detecting Ctrl
    var ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;

    if (key == 90 && ctrl) {
      let lastModification = delete_history();
      lastModification && updateToPreviousObjects(lastModification);
    }
  }

  function updateToPreviousObjects(prevObjects) {
    allObjects = prevObjects;
  }
  async function onUploadPDF(e) {
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
    const file = files[0];
    if (!file || file.type !== "application/pdf")
      return Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Verifier l'intégrité  de votre fichier"),
      });
    selectedPageIndex = -1;
    try {
      await addPDF(file);
      selectedPageIndex = 0;
    } catch (e) {
      console.log(e);
    }
  }
  async function addPDF(file) {
    try {
      const pdf = await readAsPDF(file);
      pdfFile = file;
      const numPages = pdf.numPages;
      pages = Array(numPages)
        .fill()
        .map((_, i) => pdf.getPage(i + 1));
      allObjects = pages.map(() => []);
      pagesScale = Array(numPages).fill(1);
      StartModelingTutorial();
    } catch (e) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Verifier l'intégrité  de votre fichier"),
      });
      throw e;
    }
  }
  async function onUploadImage() {
    if (selectedPageIndex >= 0) {
      addImage();
    }
  }
  async function addImage() {
    try {
      fetchFont(currentFont);
      const id = genID();
      const object = {
        id,
        type: "image",
        width: 150,
        height: 50,
        x: 0,
        y: 0,
        size: 14,
        lineHeight: 1,
        fontFamily: currentFont,
        fieldType: "Text",
        TextFormat: "Basique",
        FormatCode: 0,
        charactersNumber: 0,
        visibility: "Oui",
        lines: $_("workspace.champText"),
        relation: "",
        color: { r: 0, g: 0, b: 0 },
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  function onAddTextField() {
    if (selectedPageIndex >= 0) {
      addTextField();
    }
  }
  function addTextField(text = "New Text Field") {
    const id = genID();
    fetchFont(currentFont);
    const object = {
      id,
      text,
      type: "text",
      size: 16,
      width: 0, // recalculate after editing
      lineHeight: 1.4,
      fontFamily: currentFont,
      x: 0,
      y: 0,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function onAddDrawing() {
    if (selectedPageIndex >= 0) {
      addingDrawing = true;
    }
  }
  function addDrawing(originWidth, originHeight, path, scale = 1) {
    const id = genID();
    const object = {
      id,
      path,
      type: "drawing",
      x: 0,
      y: 0,
      originWidth,
      originHeight,
      width: originWidth * scale,
      scale,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function selectFontFamily(event) {
    const name = event.detail.name;
    fetchFont(name);
    currentFont = name;
  }
  function selectPage(index) {
    selectedPageIndex = index;
  }
  function updateMode(mode) {
    editMode = mode;
  }
  function updateObject(objectId, payload) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.map((object) =>
            object.id === objectId ? { ...object, ...payload } : object
          )
        : objects
    );
    create_history(allObjects);
  }
  function deleteObject(objectId) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.filter((object) => object.id !== objectId)
        : objects
    );
  }
  function onMeasure(scale, i) {
    pagesScale[i] = scale;
  }
  function onChangePdfName() {
    /* console.log(pdfName); */
  }
  function deleteCopyImage(id) {
    deleteObject(id);
  }
  async function copyImage(obj) {
    const {
      id,
      width,
      height,
      size,
      lineHeight,
      line,
      charactersNumber,
      fieldType,
    } = obj.detail;
    try {
      fetchFont(currentFont);
      const object = {
        id: id,
        type: "image",
        width: width,
        height: height,
        x: 0,
        y: 0,
        size: size,
        lineHeight: lineHeight,
        fontFamily: currentFont,
        fieldType: fieldType,
        charactersNumber: charactersNumber,
        lines: line,
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }

  async function savePDF() {
    if (!pdfFile || saving || !pages.length) return;
    if (pdfName == "") {
      return Swal.fire(
        $_("workspace.alertNameModel"),
        $_("workspace.verified"),
        "question"
      );
    }
    saving = true;

    /* await save(pdfFile, allObjects, pdfName, pagesScale); */
    if (allObjects.length > 0) {
      const data = allObjects;
      const file = new FormData();
      file.append("file", pdfFile);
      file.append("workspace_id", currentWorkspace.id);
      let uuid = uuidv4();

      axios
        .post(process.env.BASE_URL + "/editique_pdf/upload", file, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          axios
            .post(process.env.BASE_URL + "/editique_pdf/setFields", {
              data: data,
              fileurl: res.data.file_url,
              idModel: uuid,
              modelName: pdfName,
              workspace_id: currentWorkspace.id,
              company_id: company_id,
            })
            .then((res) => {
              saving = false;
              Swal.fire({
                position: "center",
                icon: "success",
                title: $_("workspace.travailEnregistré"),
                showConfirmButton: false,
                timer: 1500,
              }).then(() => (window.location.href = "/HomeWorkspace"));
            })
            .catch((error) => console.log(error));
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            // title: "Oops...",
            text: $_("workspace.Failed to create  new model"),
          });
        });
    } else {
      Swal.fire(
        $_("workspace.Il faut importer un pdf"),

        $_("workspace.verified")
      ).then(() => (saving = false));
    }
  }
</script>

<svelte:window
  on:dragenter|preventDefault
  on:dragover|preventDefault
  on:drop|preventDefault={onUploadPDF}
/>
<Tailwind />
<nav class=" sticky top-0 z-10" style="background-color: #151416;">
  <Header />
  <!-- <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  ">
    <div class="flex h-16 items-center justify-between">
      <div class="flex items-center">
        <div class="flex-shrink-0">
          <Neopolis />
            
        </div>
        <div class="hidden md:block">
          <div class="ml-10 flex items-baseline space-x-4">
          </div>
        </div>
      </div>
      <div class="text-white flex "><svg class="mr-2" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.12 11.78C11.0403 11.77 10.9597 11.77 10.88 11.78C10.032 11.7514 9.22821 11.3944 8.63856 10.7842C8.04891 10.174 7.71953 9.35852 7.72001 8.50999C7.72001 6.69999 9.18001 5.22999 11 5.22999C11.8594 5.22849 12.6851 5.5648 13.2988 6.16639C13.9126 6.76798 14.2654 7.58668 14.2811 8.44597C14.2969 9.30527 13.9743 10.1363 13.383 10.76C12.7917 11.3837 11.9789 11.75 11.12 11.78V11.78ZM17.74 18.38C15.9023 20.0691 13.496 21.0044 11 21C8.40001 21 6.04001 20.01 4.26001 18.38C4.36001 17.44 4.96001 16.52 6.03001 15.8C8.77001 13.98 13.25 13.98 15.97 15.8C17.04 16.52 17.64 17.44 17.74 18.38V18.38Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {user}</div>
      <div class="hidden md:block">
        <div class="ml-4 flex items-center md:ml-6">
          
          <div class="relative ml-3">
            <div>
              <button
                type="button"
                class="flex max-w-xs items-center rounded-full  text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 text-white"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                on:click={disconnect}
              >
               
               
                
                Déconnecter  <svg class="ml-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style="fill: white;"
                ><path
                  d="M10 9.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7zm6-3c-1.787 0-3.46.474-4.911 1.295l.228.2 1.396 1.221c1.004-.456 2.114-.716 3.287-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.283-.26-3.288-.715l-1.396 1.221-.228.2c1.452.82 3.125 1.294 4.912 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z"
                /></svg>
              </button>
            </div>

          </div>
        </div>
      </div>
      <div class="-mr-2 flex md:hidden">
   
        <button
          type="button"
          class="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
         
      
          <svg
            class="block h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
       
          <svg
            class="hidden h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>

 
  <div class="md:hidden" id="mobile-menu">
    <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">
      
      <a
        href="/Workspaces"
        class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
        aria-current="page">Dashboard</a
      >

    </div>
  </div> -->

  <div
    class=" z-10 sticky left-0 right-0 top-0 h-87 flex items-center

   border-b navColor"
    style="display: flex; justify-content:space-between "
  >
    <button
      on:click={() => navigate("/HomeWorkspace")}
      type="button"
      class=" retourBtn py-1 px-3 mr-2 text-sm font-medium text-gray-900 focus:outline-none rounded-lg focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800"
    >
      <svg
        class="retour"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.6 18L14 18M18 2C9.1632 2 2 9.1632 2 18C2 26.8368 9.1632 34 18 34C26.8368 34 34 26.8368 34 18C34 9.1632 26.8368 2 18 2Z"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.2001 13.1998L12.4001 17.9998L17.2001 22.7998"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>

    <button
      on:click={() => navigate("/HomeWorkspace")}
      type="button"
      class=" logoBtn py-1 px-3 mr-4 text-sm font-medium focus:outline-none focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    >
      <Logo />
    </button>
    <input
      type="file"
      name="pdf"
      id="pdf"
      on:change={onUploadPDF}
      class="hidden"
    />
    <input id="image" name="image" class="hidden" on:click={onUploadImage} />
    <div
      class="outilsBars"
      style="  display: flex; flex-direction:row; background-color:#552E6A ; justify-content:space-between; border-radius:0.3rem;padding-inline:0.8rem; width:60%"
    >
      <p class=" outils text-white mr-4 mt-1 pt-1">{$_("workspace.Outils")}</p>
      <label
        class="flex items-center justify-center h-full w-8 mr-4
cursor-pointer"
        for="image"
        data-tooltip="ADD FIELD"
      >
        <svg
          class="mt-1 newChamp"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6 17H23.4M17 23.4V10.6M12.2 33H21.8C29.8 33 33 29.8 33 21.8V12.2C33 4.2 29.8 1 21.8 1H12.2C4.2 1 1 4.2 1 12.2V21.8C1 29.8 4.2 33 12.2 33Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </label>
      <div
        style="background-color:{editMode === 'MOVE'
          ? '#6E3F88'
          : '#552E6A'} ; width:3rem;margin-right:5px;height:{editMode === 'MOVE'
          ? '2.7rem'
          : ''}"
          data-tooltip="MOVE MODE"
      >
        <div
          on:click={() => (editMode = "MOVE")}
          style="cursor:pointer"
          class="mr-4"
        >
          <svg
            class="move"
            style="margin-left: 0.42rem;margin-top:0.2rem"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.6 7.4L7.4 26.6M12.2 33H21.8C29.8 33 33 29.8 33 21.8V12.2C33 4.2 29.8 1 21.8 1H12.2C4.2 1 1 4.2 1 12.2V21.8C1 29.8 4.2 33 12.2 33Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M26.6 13.7999V7.3999H20.2M7.40002 20.1999V26.5999H13.8M7.40002 7.3999L26.6 26.5999M7.40002 13.7999V7.3999H13.8M26.6 20.1999V26.5999H20.2"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div
      data-tooltip="EDIT MODE"

        style="background-color:{editMode === 'EDIT'
          ? '#6E3F88'
          : '#552E6A'} ; width:3rem;margin-right:8px;height:{editMode === 'EDIT'
          ? '2.7rem'
          : ''}"
      >
        <div
          on:click={() => (editMode = "EDIT")}
          style="cursor:pointer"
          class="mr-4"
        >
          <svg
            class="edit"
            style="margin-left: 0.42rem;margin-top:0.2rem"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.2214 1.39746H12.0611C4.1603 1.39746 1 4.55776 1 12.4585V21.9394C1 29.8402 4.1603 33.0005 12.0611 33.0005H21.542C29.4427 33.0005 32.603 29.8402 32.603 21.9394V18.7791"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.1853 3.00873L10.7337 15.4603C10.2597 15.9344 9.78563 16.8667 9.69082 17.5461L9.01135 22.3024C8.75853 24.0247 9.97524 25.2257 11.6976 24.9886L16.4539 24.3092C17.1175 24.2144 18.0498 23.7403 18.5397 23.2663L30.9913 10.8147C33.1403 8.66568 34.1516 6.16904 30.9913 3.00873C27.831 -0.151568 25.3343 0.859729 23.1853 3.00873V3.00873Z"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div disabled class=" copyy mr-4 mt-1">
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6 23.4H6.488C2.824 23.4 1 21.576 1 17.912V6.488C1 2.824 2.824 1 6.488 1H13.8C17.464 1 19.288 2.824 19.288 6.488"
            stroke="#70557E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.592 21.7996H26.808M24.2 24.4076V19.1916M27.512 32.9996H20.2C16.536 32.9996 14.712 31.1756 14.712 27.5116V16.0876C14.712 12.4236 16.536 10.5996 20.2 10.5996H27.512C31.176 10.5996 33 12.4236 33 16.0876V27.5116C33 31.1756 31.176 32.9996 27.512 32.9996Z"
            stroke="#70557E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <input
      placeholder={$_("workspace.nomModele")}
      type="text"
      class="nameInput"
      style="border-radius: 0.2rem;padding-left:0.5rem; margin:0.7rem;padding:0.3rem;width:48%; background-color:white"
      bind:value={pdfName}
      on:change={onChangePdfName}
    />

    <button
      on:click={savePDF}
      class=" saveBtn text-white font-medium py-1 px-3
     mr-3 rounded"
      style="background-color: #71ACFA;width:15%"
      class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
      class:bg-blue-700={pages.length === 0 || saving || !pdfFile}
    >
      {saving ? "..." : $_("workspace.enregistrer")}
    </button>

    <span
      disabled
      class=" exportBtn font-medium py-1 px-3
     mr-3 rounded"
      style="background-color: #d9d9d9;width:15%; color:#afafaf"
      class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
      class:bg-blue-700={pages.length === 0 || saving || !pdfFile}
    >
      {$_("workspace.exporter")}
    </span>
    <input
      type="file"
      name="pdf"
      id="pdf"
      on:change={onUploadPDF}
      class="hidden"
    />
    <input id="image" name="image" class="hidden" on:click={onUploadImage} />
    <label
      class="importBtn font-medium mr-3 rounded cursor-pointer"
      style="background-color: #F3EAF8 !important; color:#945FAF !important; padding:0.2rem;width:30%;text-align:center"
      for="pdf"
    >
      {$_("workspace.ImportPdf")}
    </label>
    <!-- <button
    on:click={onUploadImage}
    class=" font-medium	 py-1 px-3
    md:px-4 mr-3 md:mr-4 rounded"
    style="background-color: #F3EAF8; color:#945FAF "
  
  >
    Importer un PDF
  </button> -->

    <button
      on:click={() => {
        window.scrollTo({
          top: window.scrollY + pdfpageHeight * 2.6,
          behavior: "smooth",
        });
        selectedPageIndex < allObjects.length - 1 &&
          (selectedPageIndex = selectedPageIndex + 1);
      }}
      class="mr-2"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.3 19H12.7C17.2 19 19 17.2 19 12.7V7.3C19 2.8 17.2 1 12.7 1L7.3 1C2.8 1 1 2.8 1 7.3L1 12.7C1 17.2 2.8 19 7.3 19Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.82275 8.77637L9.99975 11.9444L13.1768 8.77637"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <!-- <h1
    on:click={() =>
      window.scrollTo({
        top: pdfpageHeight * 2.6 * selectedPageIndex,
        behavior: "smooth",
      })}
    class="text-white m-5 cursor-pointer"
  >
    Page sélectionnée
  </h1>-->
    <span
      class="rounded-l-sm npage"
      style="color: #898989; background-color:white; padding-inline:0.3rem;width:25% !important "
      >{$_("workspace.numPage")}</span
    >
    <form
      on:submit={(e) => {
        e.preventDefault();
        window.scrollTo({
          top: pdfpageHeight * 2.6 * selectedPageIndex,
          behavior: "smooth",
        });
      }}
    >
      <input
        type="number"
        min="1"
        max={allObjects.length}
        step="1"
        value={selectedPageIndex + 1}
        on:change|preventDefault={(e) =>
          (selectedPageIndex = e.target.value - 1)}
        class="h-6 w-12 text-center flex-shrink-0 focus:outline-none rounded-r-sm"
      />
    </form>

    <button
      on:click={() => {
        window.scrollTo({
          top: window.scrollY - pdfpageHeight * 2.6,
          behavior: "smooth",
        });
        selectedPageIndex > 0 && (selectedPageIndex = selectedPageIndex - 1);
      }}
      class="ml-2"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.3 1H12.7C17.2 1 19 2.8 19 7.3V12.7C19 17.2 17.2 19 12.7 19H7.3C2.8 19 1 17.2 1 12.7V7.3C1 2.8 2.8 1 7.3 1Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.82275 11.2236L9.99975 8.05563L13.1768 11.2236"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>

    <h1
      style="width: 12%;"
      class="text-white m-5 cursor-pointer font-Roboto text-xs font-normal pagesN"
    >
      {allObjects.length} pages
    </h1>
  </div>
</nav>

{#if addingDrawing}
  <div
    transition:fly={{ y: -200, duration: 500 }}
    class="fixed z-10 top-0 left-0 right-0 border-b border-gray-300 bg-white
      shadow-lg"
    style="height: 50%;"
  >
    <DrawingCanvas
      on:finish={(e) => {
        const { originWidth, originHeight, path } = e.detail;
        let scale = 1;
        if (originWidth > 500) {
          scale = 500 / originWidth;
        }
        addDrawing(originWidth, originHeight, path, scale);
        addingDrawing = false;
      }}
      on:cancel={() => (addingDrawing = false)}
    />
  </div>
{/if}
{#if pages.length}
  <div class="flex justify-center px-5 w-full md:hidden">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-writing"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M20 17v-12c0 -1.121 -.879 -2 -2 -2s-2 .879 -2 2v12l2 2l2 -2z" />
      <path d="M16 7h4" />
      <path d="M18 19h-13a2 2 0 1 1 0 -4h4a2 2 0 1 0 0 -4h-3" />
    </svg>

    <input
      placeholder={$_("workspace.nomModele")}
      type="text"
      class="flex-grow bg-transparent"
      bind:value={pdfName}
      on:change={onChangePdfName}
    />
  </div>
  <div
    class="fixed top-0 z-1 bottom-0 lg:left-0 p-2 overflow-y-auto text-center sidebarr"
    style="width:20%"
  />
  <div class="w-full">
    {#each pages as page, pIndex (page)}
      <div
        class="p-5 w-full flex flex-col items-end overflow-hidden"
        on:mousedown={() => selectPage(pIndex)}
        on:touchstart={() => selectPage(pIndex)}
      >
        <div
          class="relative shadow-lg {editMode === 'MOVE'
            ? 'cursor-move'
            : 'cursor-crosshair'}"
          class:shadow-outline={pIndex === selectedPageIndex}
        >
          <PDFPage
            on:measure={(e) => onMeasure(e.detail.scale, pIndex)}
            {page}
          />
          <div
            class="absolute top-0 left-0 transform origin-top-left"
            style="transform: scale({pagesScale[pIndex]}); touch-action: none;"
          >
            {#each allObjects[pIndex] as object (object.id)}
              {#if object.type === "image"}
                <ImageUrl
                  on:update={(e) => updateObject(object.id, e.detail)}
                  on:delete={() => deleteObject(object.id)}
                  x={object.x}
                  y={object.y}
                  width={object.width}
                  size={object.size}
                  lineHeight={object.lineHeight}
                  height={object.height}
                  fontFamily={object.fontFamily}
                  pageScale={pagesScale[pIndex]}
                  typeSelected={object.fieldType}
                  charactersNumber={object.charactersNumber}
                  line={object.lines}
                  relation={object.relation}
                  visibility={object.visibility}
                  color={object.color}
                  checkFormat={object.checkFormat}
                  dateFormat={object.dateFormat}
                  TextFormat={object.TextFormat}
                  _currentVariant={object.variant}
                  {editMode}
                  on:mode={(e) => updateMode(e.detail)}
                  on:copy={(obj) => copyImage(obj)}
                  on:deletecopy={(obj) => deleteCopyImage(obj.detail)}
                />
              {/if}
            {/each}
          </div>
        </div>
      </div>
    {/each}
  </div>
{:else}
  <div
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center"
  >
    <LoadingPdf />
  </div>
{/if}

<BackToTop />

<BackToPreviousPage />
<GotoNextPage />

<style>
  /*This would all go into the global.css file*/
	[data-tooltip] {
  position: relative;
  z-index: 2;
  display: block;
}

[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
	transition: .2s ease-out;
	transform: translate(-50%, 5px)
}

[data-tooltip]:before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  padding: 7px;
	width: 100%;
  min-width: 70px;
	max-width: 250px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
	transition: .2s ease-out
}

[data-tooltip]:after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
	transform: translate(-50%, 0)
}
[data-tooltip=false]:hover:before,
[data-tooltip=false]:hover:after {
  visibility: hidden;
  opacity: 0;
}
  .navColor {
    background-color: #3b194d;
  }
  .sidebarr {
    background-color: #624771;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1590px) {
    .retourBtn {
      margin-left: 0;
      padding-right: 0;
      padding-left: 4px;
    }
    .retour {
      width: 28px;
    }
    .logoBtn {
      margin-right: 0.5rem;
      padding: 0;
    }
    .logo {
      width: 160px;
    }
    .outils {
      margin-right: 5px;
    }
    .outilsBars {
      display: flex;
      flex-direction: row;
      margin: 0.2rem;

      width: 25%;
    }
    .newChamp {
      width: 25px;
    }
    .move {
      width: 25px;
    }
    .edit {
      width: 25px;
    }
    .copyy {
      width: 25px;
      margin-right: 0.2rem;
    }
    .nameInput {
      width: 48% !important;
      border-radius: 0.2rem !important;
      padding: 0.3rem !important;
      margin: 0.2rem !important;
    }
    .saveBtn {
      padding-inline: 0.2rem;
      padding-block: 4px;
      font-size: 0.9rem;
      margin-right: 0.2rem;
      font-weight: 500;
      width: 15%;
    }
    .exportBtn {
      padding-inline: 0.4rem;
      padding-block: 4px;
      font-size: 0.9rem;
      margin-right: 0.2rem;
      font-weight: 500;
      width: 15%;
    }
    .importBtn {
      font-size: 0.9rem;
      font-weight: 500;
      width: 30%;
      text-align: center;
      margin-right: 0.3rem;
      padding: 2.9px !important;
    }
    .pagesN {
      padding-left: 0.3rem;
      margin: 0;
      width: 12%;
    }

    .npage {
      width: 15% !important;
    }
  }
</style>
